import { useEffect, useState } from "books/act";
import { styled, Skeleton, Grow, Box } from "fashion/fabrics";
import { delay } from "books/reflow";
import useElementSizeWatcher from "gears/useElementSizeWatcher";
import AtlasGlobe from "nodes/atlas/AtlasGlobe";

const GLOBE_GROW_DELAY = 2000;
const GLOBE_GROW_TIMEOUT = 3000;
const ATLAS_INNER_GLOBE_RATIO = 0.65;

const CenterBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const AtlasGrowGlobe = () => {
  const [growIn, setGrowIn] = useState(false);
  const { width, height, elementRef } = useElementSizeWatcher();
  const globeSize = Math.min(width || height, height || width);

  useEffect(() => {
    const timerId = delay(GLOBE_GROW_DELAY, () => setGrowIn(true));
    return () => clearTimeout(timerId);
  }, []);

  return (
    <Box position="relative">
      <CenterBox position="absolute" width={globeSize} height={globeSize}>
        <Skeleton
          animation="wave"
          variant="circular"
          width={globeSize * ATLAS_INNER_GLOBE_RATIO}
          height={globeSize * ATLAS_INNER_GLOBE_RATIO}
        />
      </CenterBox>
      <Grow
        in={growIn}
        timeout={GLOBE_GROW_TIMEOUT}
        style={{ transformOrigin: "center" }}
      >
        <Box ref={elementRef} style={{ cursor: "grab" }}>
          <AtlasGlobe globeSize={globeSize} />
        </Box>
      </Grow>
    </Box>
  );
};

export default AtlasGrowGlobe;
