import { useMemo } from "books/act";
import { useTheme } from "fashion/fabrics";
import { DoubleSide, MeshLambertMaterial } from "three";
import { get } from "books/reflow";
import Globe from "react-globe.gl";
import * as topojson from "topojson-client";
import { WORLD_ATLAS_1_100_SCALE } from "books/worldatlas";

type Props = {
  globeSize: number;
};

const AtlasGlobe = ({ globeSize }: Props) => {
  const theme = useTheme();

  const polygonsData = useMemo(
    () =>
      get("features")(
        topojson.feature(
          WORLD_ATLAS_1_100_SCALE,
          WORLD_ATLAS_1_100_SCALE.objects.land
        )
      ),
    []
  );
  const polygonsMaterial = useMemo(
    () =>
      new MeshLambertMaterial({
        color: theme.palette.secondary.main,
        side: DoubleSide,
      }),
    [theme]
  );

  return (
    <Globe
      width={globeSize}
      height={globeSize}
      backgroundColor={theme.palette.background.default}
      showGlobe={false}
      showAtmosphere={false}
      polygonsData={polygonsData}
      polygonCapMaterial={polygonsMaterial}
      polygonSideColor={theme.palette.background.default}
    />
  );
};

export default AtlasGlobe;
