import { styled, Box, Typography } from "fashion/fabrics";
import AtlasLogo from "nodes/atlas/AtlasLogo";

const LogoBox = styled(Box)(() => ({
  margin: "auto",
}));

const LOGO_SIZE = 100;

const AtlasAvatar = () => (
  <Box>
    <LogoBox width={LOGO_SIZE} height={LOGO_SIZE}>
      <AtlasLogo />
    </LogoBox>
    <Typography mt={0.5} variant="h2" textAlign="center">
      Mizony, Inc
    </Typography>
  </Box>
);

export default AtlasAvatar;
