import { Link, Box, Stack, Button, Typography } from "fashion/fabrics";
import LaunchIcon from "@mui/icons-material/Launch";

const LINK_CONCEPT_CARDS = "https://concept.memoir.cards";

const AtlasDiscovery = () => (
  <Stack gap={1}>
    <Typography textAlign="center" variant="body2" color="text.secondary">
      Introducing a creative way to visualize information, a card platform
      domain{" "}
      <Typography
        color="secondary"
        component={Link}
        href={LINK_CONCEPT_CARDS}
        title={LINK_CONCEPT_CARDS}
        variant="body2"
      >
        concept.memoir.cards
      </Typography>{" "}
      to organize information with UI cards.
    </Typography>
    <Box alignSelf="center" mt={1}>
      <Button
        size="large"
        endIcon={<LaunchIcon />}
        color="secondary"
        variant="outlined"
        component={Link}
        href={LINK_CONCEPT_CARDS}
        title={LINK_CONCEPT_CARDS}
      >
        Card Concept
      </Button>
    </Box>
  </Stack>
);

export default AtlasDiscovery;
