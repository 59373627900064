import { AppBar, Box, styled, Container } from "fashion/fabrics";
import AtlasDiscovery from "nodes/atlas/AtlasDiscovery";
import AtlasAvatar from "nodes/atlas/AtlasAvatar";
import AtlasBottomToolbar from "nodes/atlas/AtlasBottomToolbar";
import AtlasGrowGlobe from "nodes/atlas/AtlasGrowGlobe";

const BottomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  top: "auto",
  bottom: 0,
}));

const AtlasOutlet = () => (
  <Container sx={{ my: 2 }} maxWidth="xs">
    <AtlasAvatar />
    <Box py={2} />
    <AtlasDiscovery />
    <AtlasGrowGlobe />
    <BottomAppBar position="fixed">
      <AtlasBottomToolbar />
    </BottomAppBar>
  </Container>
);

export default AtlasOutlet;
