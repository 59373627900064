import { Stack, Typography, Toolbar } from "fashion/fabrics";

const AtlasBottomToolbar = () => (
  <Toolbar sx={{ justifyContent: "center" }}>
    <Stack>
      <Typography textAlign="center" color="text.disabled" variant="caption">
        © All Rights Reserved.
      </Typography>
      <Typography textAlign="center" color="text.disabled" variant="caption">
        Mizony, Inc.
      </Typography>
    </Stack>
  </Toolbar>
);

export default AtlasBottomToolbar;
