import { Outlet, BrowserRouter, Routes, Route } from "react-router-dom";
import AtlasOutlet from "nodes/atlas/AtlasOutlet";

export enum STATIC_ROUTES {
  ROOT = "/",
}

const AtlasRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="*" element={<AtlasOutlet />} />
        <Route index element={<AtlasOutlet />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AtlasRoutes;
