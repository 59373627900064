import ThemeProvider from "firms/theme/ThemeProvider";
import AtlasRoutes from "nodes/atlas/AtlasRoutes";

const Atlas = () => (
  <ThemeProvider>
    <AtlasRoutes />
  </ThemeProvider>
);

export default Atlas;
